<template>
  <div :class="direction">
    <img :src="(color && text) ? `/assets/img/hot-offer-${color}.png`: '/assets/img/hot-offer.png'" class="hot-offer-badge"/>
    <p class="badge-text" :class="{'multi-row' : text.includes(' ')}">{{text}}</p>
  </div>
</template>
<script>
export default {
  name: 'hot-offer-icon',
  props: {
    color: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: 'right',
    },
  },
};
</script>
<style scoped>
  .right .hot-offer-badge{
    transform: rotate(90deg);
  }
  .right .badge-text{
    transform: rotate(45deg);
    top: calc(50% - 2.2rem);
  }
  .left .hot-offer-badge{
    transform: rotate(0deg);
  }
  .left .badge-text{
    transform: rotate(-45deg);
    top: calc(50% - 2.2rem);
    left: 0;
    /* transform-origin: top left; */
  }
  .hot-offer-badge{
    width: 100%;
    height: 100%;
  }
  .badge-text{
    position: absolute;
    color: white;
    line-height: 1.2rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
  .right .badge-text.multi-row{
    left: calc(50% - 0.8rem);
  }
  .left .badge-text.multi-row{
    left: calc(50% - 2.2rem);
    width: min-content;
  }
  @media (max-width: 479px) {
    .left .badge-text{
      font-size: 0.6rem;
      top: calc(50% - 1.1rem);
    }
  }
</style>
